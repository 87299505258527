import api from "./api";


// NEW
    // SUBSCRIPTION
        export function subscription(installments){
            if(installments == 7){ return 'Assinatura - Semanal';	}
            if(installments == 15){ return 'Assinatura - Quizenal'; }
            if(installments == 1){ return 'Assinatura - Mensal'; }
            if(installments == 2){ return 'Assinatura - Bimestral'; }
            if(installments == 3){ return 'Assinatura - Trimestral'; }
            if(installments == 6){ return 'Assinatura - Semestral'; }
            if(installments == 12){ return 'Assinatura - Anual'; }
        }
    // SUBSCRIPTION

    // SITUATION
        export function situation(status){
            if(status == 1){ return 'Pago'; }
            if(status == 2){ return 'Cancelado'; }
            else { return 'Pendente';	}
        }
    // SITUATION
// NEW






// NUMEROS
    // PRICE
        export function price($value, ballot=0){
            if(ballot==1){

            } else {

            }
            const options = {
                style: 'currency',
                currency: 'BRL'
            }
            let $return = new Intl.NumberFormat('pt-br', options).format($value)

            return $return;
        }
    // PRICE

    // PRICE_
        export function price_($value){
            $value = replace(' ', '', $value);
            $value = replace('R$', '', $value);
            $value = replace(',', '', $value);
            $value = replace('.', '', $value);
            $value = parseInt($value);
            $value = parseInt($value);
            $value = $value/100;
            return $value;
        }
    // PRICE_

    // RAND
        export function rand(min=100000, max=999999){
            let $return = Math.floor(Math.random() * (max - min + 1) + min);
            return $return;
        }
        export function rand_str(){
            let $return = (Math.random() + 1).toString(36).substring(7);
            return $return;
        }
    // RAND
// NUMEROS







// DATE
    // DATE
        export function date($date){
            let ex_1 = $date.split(`T`);
            let ex = ex_1[0].split(`-`);
            return `${ex[2]}/${ex[1]}/${ex[0]}`;
        }
    // DATE

    // HOJE
        export function hoje(){
            let date = new Date();
            return`${date.getFullYear()}-${(`00`+(date.getMonth()+1)).slice(-2)}-${(`00`+date.getDate()).slice(-2)}`;
        }
    // HOJE

    // BIRTH
        export function birth(){
            let TODAY = new Date()
            let TODAY_15 = new Date(TODAY.getFullYear(), TODAY.getMonth()-(18*12)+1, TODAY.getDate());
            return`${TODAY_15.getFullYear()}-${(`00`+(TODAY_15.getMonth()+1)).slice(-2)}-${(`00`+TODAY_15.getDate()).slice(-2)}`;
        }
    // BIRTH
// DATE







// MASK
    const $masks = {
        price(value){
            if(!value){ return ''; }
            else {
                const cleanValue = +value.replace(/\D+/g, '')
                const options = {
                    style: 'currency',
                    currency: 'BRL'
                }
                return new Intl.NumberFormat('pt-br', options).format(cleanValue / 100)
            }
        },
    }
    export function mask__(field){
        setTimeout(() => {        
            document.querySelectorAll(`input[mask="${field}"]`).forEach(item => {
                if(!item.classList.contains('mask-ok')){
                    item.classList.add('mask-ok');
                }

                item.addEventListener('input', e => {
                    if($masks[field] != null){
                        console.log(e);
                        e.target.value = $masks[field](e.target.value);
                    }
                }, false);
                item.dispatchEvent(new Event('input'));
            });
        }, .5);
    }
// MASK






// UTEIS
    // REPLACE
        export function replace($de, $para, $txt){
            let $return = ``;
            if($txt){
                $return = $txt;
                try {
                    if($txt.indexOf($de) >= 0){
                        let $pos = $txt.indexOf($de);
                        while ($pos > -1){
                            $return = $return.replace($de, $para);
                            $pos = $return.indexOf($de);
                        }
                    }
                } catch(e) {}
            }
            return $return;
        }
    // REPLACE

    // NAME
        export function name__(name, $tipo=0){ // 0-> So nome; 1->So Sobrenome; 2->x qtd de nomes
            let ex = name.split(' ');

            // NOME
                if($tipo == 0){
                    return ex[0];
                }
            // NOME

            // SOBRENOME
                if($tipo == 1){
                    return ex[1] ? ex[1] : ``;
                }
            // SOBRENOME

            // QTD
                if($tipo >= 2){
                    let $return = ``;
                    for (let i = 0; i < $tipo; i++) {
                        $return += ex[i] ? `${ex[i]} ` : ``;

                        // SO NOME FOR MENOR QUE X VAR
                            if(ex[i] != null && ex[i].length < 4){
                                i++;
                                $return += (ex[i]!=null && ex[i]) ? `${ex[i]} ` : ``;
                            }
                        // SO NOME FOR MENOR QUE X VAR
                    }
                    $return = $return.trim();

                    return $return;
                }
            // QTD
        }
    // NAME

    // NAME_INI
        export function name_ini(name){
            let $return = ``;

            if(name != null && name){
                let ex = name.split(' ');

                $return = ex[0][0];
                if(ex[1] != null && ex[1][0] != null){
                    $return += ex[1][0];
                }
            }

            return $return;
        }
    // NAME_INI

    // IMPLODE
        export function implode(separator, array){
            if(typeof array === "object"){
                let array_temp = array
                array = [];
				for(let key in array_temp){
                    array.push(key);
				}
            }
            return array.join(separator);
        }
    // IMPLODE

    // LIMIT
        export function limit(text, limit, ellipsis='...', tirm=1){
            if(text != null && text.length > limit) {
                text = text.substr(0, limit);
                if(tirm){
                    text = text.trim();
                }
                text += ellipsis;
            }

            return text;
        }
    // LIMIT

    // TITULO
        export function titulo(name, o, s=``){
            let $return = {};

            $return.o = o ? o : ``;
            $return.os = o ? `${o}s` : ``;

            $return.m = name ? name : ``;
            $return.ms = name ? `${name}s` : ``;
            $return.mo = (name && o) ? `${o} ${name}` : ``;
            $return.mos = (name && o) ? `${o}s ${name}s` : ``;

            $return.u = name ? first_letter_uppercase(name): ``;
            $return.us = name ? first_letter_uppercase(`${name}s`): ``;
            $return.uo = (name && o) ? first_letter_uppercase(`${o} ${name}`): ``;
            $return.uos = (name && o) ? first_letter_uppercase(`${o}s ${name}s`): ``;

            //{ m: 'cliente', ms: 'clientes', mo: 'o cliente', mos: 'os clientes', u: 'Cliente', us: 'Clientes', uo: 'O cliente', uos: 'Os clientes' }

            return $return;
        }
    // TITULO

    // FIRST_LETTER_UPPERCASE
    export function first_letter_uppercase(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // NO_ACECENT
        export function no_accent($strToReplace){
            let $str_acento = "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ";
            let $str_no_accent = "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC";
            let $return = "";
            for (let i = 0; i < $strToReplace.length; i++){
                if ($str_acento.indexOf($strToReplace.charAt(i)) != -1){
                    $return += $str_no_accent.substr($str_acento.search($strToReplace.substr(i, 1)), 1);
                } else {
                    $return += $strToReplace.substr(i, 1);
                }
            }
            return $return;
        }
    // NO_ACECENT

    // IS_E
        export function is_e($e, $all=0){
            let $return = ``;

            if($e.classList != null){
                $return = $e;
            } else if($all){
                $return = document.querySelectorAll($e);
                if(!$return[0]){
                    $return = '';
                }
            } else {
                $return = document.querySelector($e);
            }

            return $return;
        }
    // IS_E
// UTEIS







// ARRAY
    // MONTH
        export function month($month){
            let $return = ['', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
            return $return[$month];
        }
        export function month_ab($month){
            let $return = ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
            return $return[$month];
        }
        export function month_select($month=0){
            let $return = [
                        {'id': 1, 'name': 'Janeiro', 'selected': ($month==1)}, 
                        {'id': 2, 'name': 'Fevereiro', 'selected': ($month==2)}, 
                        {'id': 3, 'name': 'Março', 'selected': ($month==3)}, 
                        {'id': 4, 'name': 'Abril', 'selected': ($month==4)}, 
                        {'id': 5, 'name': 'Maio', 'selected': ($month==5)}, 
                        {'id': 6, 'name': 'Junho', 'selected': ($month==6)}, 
                        {'id': 7, 'name': 'Julho', 'selected': ($month==7)}, 
                        {'id': 8, 'name': 'Agosto', 'selected': ($month==8)}, 
                        {'id': 9, 'name': 'Setembro', 'selected': ($month==9)}, 
                        {'id': 10, 'name': 'Outubro', 'selected': ($month==10)}, 
                        {'id': 11, 'name': 'Novembro', 'selected': ($month==11)}, 
                        {'id': 12, 'name': 'Dezembro', 'selected': ($month==12)}
                    ];
            return $return;
        }
    // MONTH

    // STATE
        export function state($state=''){
            let $return = [
                        {'id': 'AC', 'name': 'Acre', 'selected': ($state=='AC')}, 
                        {'id': 'AL', 'name': 'Alagoas', 'selected': ($state=='AL')}, 
                        {'id': 'AM', 'name': 'Amazonas', 'selected': ($state=='AM')}, 
                        {'id': 'AP', 'name': 'Amapá', 'selected': ($state=='AP')}, 
                        {'id': 'BA', 'name': 'Bahia', 'selected': ($state=='BA')}, 
                        {'id': 'CE', 'name': 'Ceará', 'selected': ($state=='CE')}, 
                        {'id': 'DF', 'name': 'Distrito Federal', 'selected': ($state=='DF')}, 
                        {'id': 'ES', 'name': 'Espírito Santo', 'selected': ($state=='ES')}, 
                        {'id': 'GO', 'name': 'Goiás', 'selected': ($state=='GO')}, 
                        {'id': 'MA', 'name': 'Maranhão', 'selected': ($state=='MA')}, 
                        {'id': 'MG', 'name': 'Minas Gerais', 'selected': ($state=='MG')}, 
                        {'id': 'MS', 'name': 'Mato Grosso do Sul', 'selected': ($state=='MS')}, 
                        {'id': 'MT', 'name': 'Mato Grosso', 'selected': ($state=='MT')}, 
                        {'id': 'PA', 'name': 'Pará', 'selected': ($state=='PA')}, 
                        {'id': 'PB', 'name': 'Paraíba', 'selected': ($state=='PB')}, 
                        {'id': 'PE', 'name': 'Pernambuco', 'selected': ($state=='PE')}, 
                        {'id': 'PI', 'name': 'Piauí', 'selected': ($state=='PI')}, 
                        {'id': 'PR', 'name': 'Paraná', 'selected': ($state=='PR')}, 
                        {'id': 'RJ', 'name': 'Rio de Janeiro', 'selected': ($state=='RJ')}, 
                        {'id': 'RN', 'name': 'Rio Grande do Norte', 'selected': ($state=='RN')}, 
                        {'id': 'RO', 'name': 'Rondônia', 'selected': ($state=='RO')}, 
                        {'id': 'RR', 'name': 'Roraima', 'selected': ($state=='RR')}, 
                        {'id': 'RS', 'name': 'Rio Grande do Sul', 'selected': ($state=='RS')}, 
                        {'id': 'SC', 'name': 'Santa Catarina', 'selected': ($state=='SC')}, 
                        {'id': 'SE', 'name': 'Sergipe', 'selected': ($state=='SE')}, 
                        {'id': 'SP', 'name': 'São Paulo', 'selected': ($state=='SP')}, 
                        {'id': 'TO', 'name': 'Tocantins', 'selected': ($state=='TO')}, 
                    ];
            return $return;
        }
    // STATE
// ARRAY







// OTHERS
    // ZIP_CODE
        export function zip_code(FORM, OBJ){
            if(FORM.v.zip_code.length >= 10){
                api(`/../../../others/zip_code.php`, { zip_code: FORM.v.zip_code }, (request) => {
                    FORM.v.address = request.rua;
                    FORM.v.neighborhood = request.bairros;
                    FORM.v.state = request.estados ? request.estados : "";
                    FORM.v.city = request.cidades ? request.cidades : "";
                    select_state(FORM, OBJ);
                })
            }
        }
    // ZIP_CODE


    // SELECT_STATE
        export function select_state(FORM, OBJ, zerar_cidade=0, $load=1){
            api(`/../../../others/city.php`, { state: FORM.v.state }, (request) => {
                OBJ.city = request;
                if(zerar_cidade){
                    FORM.v.city = "";
                }
            }, $load)
        }
    // SELECT_STATE
// OTHERS







// CODIFICACAO
    export function cod(type, txt){
        let $return = txt;
 
        if(type == `asc`){
            $return = replace(`'`, `&#39;`, $return);
            $return = replace(`"`, `&#34;`, $return);
            $return = replace(`´`, `&#180;`, $return);
            $return = replace('`', `&#96;`, $return);
            $return = replace(`?`, `&#63;`, $return);
            $return = replace(`!`, `&#33;`, $return);
            $return = replace(`=`, `&#61;`, $return);
            $return = replace(`<`, `&#60;`, $return);
            $return = replace(`>`, `&#62;`, $return);
        }

        return $return;
    }
// CODIFICACAO
