<script setup>

    import { ref, onMounted } from 'vue';

    // VARS
        const LOADING = ref(1);
    // VARS

    // ONMOUNTED
        onMounted(() => {
            setTimeout(() => { LOADING.value = 0 }, 500);
        });
    // ONMOUNTED
</script>


<template>
    <router-view :key="$route.fullPath" />
   
    <!-- LOADING -->
        <div v-if="LOADING" class="posf t0 l0 z910 w100p h100p back_fff"><div class="posf t50p l50p z-1" style="padding: 10px; margin: -110px 0 0 -110px; font-size: 0;"><div style="width:100%; height:100%" class="lds-eclipse"><div></div></div></div></div>
	<!-- LOADING -->

	<!-- EVENTS__ -->
        <div class="events__">
            <div class="load_1"><div><div class="lds-eclipse"><span>Carregando...</span><div></div></div></div></div>
            <div class="load_2"><img :src="`${DIR}/web/img/padrao/carregando1.gif`" /> <span> <span class="porcentagem"></span> Carregando... </span> </div>
            <div class="load_branco"><div><div class="lds-eclipse"><div></div></div></div></div>
            <div class="alerts__"><div class="alerts"></div></div>
            <div class="errors__"></div>
        </div>
    <!-- EVENTS__ -->
</template>