import { is_e } from '@/services/functions';

// ANIMATION
    export function animation($classe, $animation, $delay=5){
        let $e = is_e($classe);
        if($e){
            $e.classList.add(`animation`);

            // FADE
                if($animation == 'fadeIn'){
                    $e.classList.add(`fadeIn`);
                    $e.style.display = "block";
                    //setTimeout(function(){ $e.classList.remove(`fadeIn`); }, 2000);
                }
                if($animation == 'fadeOut'){
                    $e.classList.add(`fadeOut`);
                    setTimeout(function(){
                        $e.remove();
                    }, $delay*100);
                }
                if($animation == 'fadeInOut'){
                    // In
                        $e.classList.add(`fadeIn`);
                        $e.style.display = "block";
                    // In
                    // Out
                        setTimeout(function(){
                            $e.classList.remove(`fadeIn`);
                            animation($e, `fadeOut`);
                        }, $delay*1000);
                    // Out
                }
            // FADE

            // SLIDE
                if($animation == 'slideDown'){
                    let $e = is_e($classe);
                    if($e){
                        $e.classList.add(`animation`);
                        $e.classList.add(`slideDown`);
                        $e.style.display = "block";
                        //setTimeout(function(){ $e.classList.remove(`slideDown`); }, 2000);
                    }
                }
                if($animation == 'slideUp'){
                    let $e = is_e($classe);
                    if($e){
                        $e.classList.add(`animation`);
                        $e.classList.add(`slideUp`);
                        $e.style.display = "block";
                        //setTimeout(function(){ $e.classList.remove(`slideUp`); }, 2000);
                    }
                }
            // SLIDE
        }

    }
// ANIMATION