
// LOCALHOST
    export function LOCALHOST() {
        if(window.location.href.split('http://localhost:')[1]){
            return 1;
        }
        return 0;
    }
// LOCALHOST







// DIR_FRONT
    export function DIR_FRONT() {
        if(window.location.href.split('http://localhost:')[1]){
            let $ex = window.location.href.split('/');
            return `${$ex[0]}//${$ex[1]}${$ex[2]}`;
        }
        return process.env.VUE_APP_DIR;
    }
// DIR_FRONT
