import { animation } from '@/services/animation';

// ALERTS
    export function alerts($acao=0, $txt='', $varios=0, $delay=0){
        if(!$delay){
            $delay = 5;
            if($acao){
                $delay = 3;
            }
        }

        if(!$varios){
            document.querySelectorAll('.events__ .alerts .alert').forEach($item => $item.style.display = 'none');
        }

        if(!$txt){
            $txt = $acao ? 'Operação Realizada com Sucesso!' : 'Ocorreu algum erro inesperado!';
        }

        let $rand = parseInt(Math.random()*10000000);
        let $html  = ``;
        $html += `<div class="c-p acao_${$acao ? 1 : 0} alert alert_${$rand}"> `;
            $html += `<div>${$txt}</div> `;
        $html += `</div> `;

        document.querySelector('.events__ .alerts').insertAdjacentHTML('beforeend', $html);
        animation(`.events__ .alerts .alert_${$rand}`, 'fadeInOut', $delay);

        document.querySelectorAll(`.events__ .alert.alert_${$rand}`).forEach($item_1 => {
            $item_1.addEventListener('click', () => {
                animation(`.events__ .alerts .alert_${$rand}`, 'fadeOut', $delay);
            });
        })
}
// ALERTS