import { createRouter, createWebHistory } from 'vue-router'
import { beforeEach, afterEach } from '@/middlewares/request'

const routes = [
    { name: 'error404',                     path: '/:catchAll(.*)', component: () => import('@/layouts/error404.vue') },

    { name: 'home',                         path: '/', component: () => window.location = `/dashboard` },

    { name: 'login',                        path: '/login', component: () => import('@/layouts/login.vue') },

    { name: 'cadastro',                     path: '/cadastro', component: () => import('@/layouts/cadastro.vue') },
    { name: 'cadastro_step',                path: '/cadastro/:step', component: () => import('@/layouts/cadastro.vue'), },

    { name: 'dashboard',                    path: '/dashboard', component: () => import('@/layouts/dashboard.vue'),
        children: [
            { name: 'dashboard_get_1',      path: '/dashboard/:get_1', component: () => import('@/layouts/dashboard.vue'), },
            { name: 'dashboard_get_2',      path: '/dashboard/:get_1/:get_2', component: () => import('@/layouts/dashboard.vue'), },
            { name: 'dashboard_get_3',      path: '/dashboard/:get_1/:get_2/:get_3', component: () => import('@/layouts/dashboard.vue'), },
            { name: 'dashboard_get_4',      path: '/dashboard/:get_1/:get_2/:get_3/:get_4', component: () => import('@/layouts/dashboard.vue'), },
            { name: 'dashboard_get_5',      path: '/dashboard/:get_1/:get_2/:get_3/:get_4/:get_5', component: () => import('@/layouts/dashboard.vue'), },

        ]
    },

    { name: 'textos',                       path: '/textos/:name/:id', component: () => import('@/layouts/textos.vue'), },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    
})

router.beforeEach(beforeEach);
router.afterEach(afterEach);

export default router
