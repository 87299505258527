
// LOAD
    export function load(load=1){
        document.querySelectorAll(`.load_${load}`).forEach(item => {
            item.style.display = 'block';
        });
    }
// LOAD







// LOAD_CLOSE
    export function load_close(){
        for (let i = 1; i <= 2; i++) {
            document.querySelectorAll(`.load_${i}`).forEach(item => {
                item.style.display = 'none';
            });
        }
        document.querySelectorAll(`.load_branco`).forEach(item => {
            item.style.display = 'none';
        });
    }
// LOAD_CLOSE