import { useRouter } from 'vue-router';
import { alerts } from '@/services/alerts';
import { load, load_close } from '@/services/load';
import { LOCALHOST, DIR_FRONT, DIR_IMG } from '@/services/localhost';
import { userAuth, userAuth_remove, usersAccount } from '@/storages/auth';
import { cod, replace } from './functions';

const ROUTER = useRouter();
const DIR = process.env.VUE_APP_DIR;
//const API_URL = `http://localhost:4000/Sites/Talents/001_Asaas/laravel/public/api/v1`;
const API_URL = process.env.VUE_APP_API_URL;


// API
    const api = async (url, data={}, $function=null, $load=1) => {
        $load ? load() : ``;

        const api_old = () => api(url, data, $function);
        let fechar = 1;

        // AUTH
            let header = { "Content-type": "application/json", "Accept": "application/json" };
            let token = userAuth();
            if(token){
                header.Authorization = `Bearer ${token}`
            }
        // AUTH

        // HEADER
            let headers = {
                method: "GET",
                headers: header
            }

            if(data){
                // USERSACCOUNT
                    let token = usersAccount();
                    if(token){
                        data.usersAccount__ = token;
                    }
                // USERSACCOUNT

                headers = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: header
                }
            }
        // HEADER

        var req__ = ``;
        var json__ = ``;

        try {
            req__ = await fetch(`${API_URL}${url}`, headers);
            json__ = await req__.json();

            load_close();
            return computed(req__, json__, api_old, fechar, $function);

        } catch(e){
            load_close();
            error_log(req__, json__, e, api_old, fechar, $function);
        }
    }
// API







// COMMPUTED
    const computed = (req, json, api_old, fechar, $function) => {
        // 200
            if(req.status == 200){
                treatment(req, json, api_old, fechar, $function);
                return json;
            }
        // 200

        // 401 (Unauthorized)
            else if(req.status == 401){
                userAuth_remove();
                window.location = `${DIR_FRONT()}/login/`;
                return false;
            }
        // 401 (Unauthorized)

        // 422 (Unprocessable Content)
            else if(req.status == 422){
                treatment(req, json, api_old, fechar);
            }
        // 422 (Unprocessable Content)

        // ELSE
            else {
                error_log(req, json, ``, api_old, fechar, $function);
                return false;
            }
        // ELSE
    }
// COMMPUTED







// TREATMENT
    const treatment = (req, json, api_old, fechar, $function=``) => {
        // ERRORS
            if((json.errors && json.errors != null) || (json.message && json.message != null)){

                // ERRORS
                    if(Array.isArray(json.errors) ||  json.message && json.message != null){
                        if(json.errors == null){
                            json.errors = [`${json.message}`];
                        }
                        document.querySelectorAll('.events__ .alerts').forEach(item => { item.innerHTML = '' });
                        if(json?.errors != null){
                            for(let $key in json.errors){
                                let item = json.errors[$key];
                                if(Array.isArray(item)){
                                    for(let $value_1 of item){
                                        alerts(0, $value_1, 1);
                                    }
                                } else {
                                    alerts(0, item, 1);
                                }
                            }
                        }
                    }
                // ERRORS           

                // ERROR_LOG
                    else {
                        error_log(req, json, json.errors, api_old, fechar, $function);
                        return false;
                    }
                // ERROR_LOG
            }
        // ERRORS

        // FUNCTION
            else if($function){
                $function(json);
            }
        // FUNCTION

        // ALERTS
            if(json.alert != null && json.errors == null){
                if(json.alert){
                    alerts(1, (json.alert==1 ? '': json.alert), 0, json.delay);
                } else {
                    alerts(0, '', 0, json.delay);
                }
            }
        // ALERTS
    }
// TREATMENT





// ERROR_LOG
    const error_log = (req, json, e, api_old, fechar, $function) => {
        error_box_alert(req, json, e, api_old, fechar, $function);
        e ? console.log(`API =>`) : ``;
        e ? console.log(e) : ``;
    }
// ERROR_LOG







// ERROR_BOX_ALERT
    const error_box_alert = (req, json, e, api_old, fechar, $function) => {

        e = cod(`asc`, e.toString());

        let txt = 'Não foi possível completar a solicitação, tente novamente mais tarde. <br>Em caso de dúvida entre em contato.';
        if(req.status == 500){
            txt = 'Não foi possível completar a solicitação, verifique a sua conexão com internet. <br>Em caso de dúvida entre em contato.';
        }
        
        let $html = ``;
        $html += `<div> `;
            $html += fechar ? `<a class="fechar"><img src="${DIR}/web/img/svg/fechar.svg" class="h20" /></a>` : ``;
            $html += `<div> `;
                $html += `<b>Atenção</b> `;
                $html += `<span>${txt}</span> `;
                $html += `<button>Tentar novamente</button> `;
            $html += `</div> `;

            $html += `<div> `;
                $html += `<div class="pb5 tar c-p c_azul link mais_detalhes">mais detalhes</div> `;
                $html += `<div class="p10 o-a mais_detalhes_box ${LOCALHOST() ? '' : 'dn'} " style="max-width: ${window.innerWidth-40}px; max-height: 500px; border: 1px solid #ccc; border-radius: 5px"> `;
                    $html += (req.status!=null || req.statusText!=null) ? `<div class="">${req.status} - ${req.statusText}</div> ` : `<div class="">500 - Internal Server Error!</div> `;
                    $html += e ? `<code class="pt10">${e}</code> ` : ``;
                    $html += `<div class="pt10"> `;
                        $html += json.file ? `<div class="">File: ${json.file}</div> ` : ``;
                        $html += json.line ? `<div class="pt2">Line: ${json.line}</div> ` : ``;
                        $html += json.message ? `<div class="pt2">Message: ${json.message}</div> ` : ``;
                    $html += `</div> `;
                $html += `</div> `;
            $html += `</div> `;

        $html += `</div> `;
        document.querySelector(`.events__ .errors__`).innerHTML = $html;

        document.querySelector(`.events__ .errors__ button`).addEventListener('click', (e) => {
            let $e = document.querySelector(`.events__ .errors__`);
            if($e){
                $e.innerHTML = '';
            }
            api_old();
        })

        document.querySelector(`.events__ .errors__ a.fechar`).addEventListener('click', (e) => {
            let $e = document.querySelector(`.events__ .errors__`);
            if($e){
                $e.innerHTML = '';
            }
        })

        document.querySelector(`.events__ .errors__ .mais_detalhes`).addEventListener('click', (e) => {
            let $e = document.querySelector(`.events__ .errors__ .mais_detalhes_box`);
            if($e.classList.contains(`dn`)){
                $e.classList.remove('dn');
            } else {
                $e.classList.add('dn');
            }
        })

    }
// ERROR_BOX_ALERT

export default api;