
// USER
    export function userAuth_set(token){
        localStorage.setItem('userAth', token);
    }

    export function userAuth(){
        return localStorage.getItem('userAth');
    }

    export function userAuth_remove(){
        localStorage.removeItem('userAth');
    }
// USER


// USER_ACCOUNT
    export function usersAccount_set(token){
        localStorage.setItem('usersAccount', token);
    }

    export function usersAccount(){
        return localStorage.getItem('usersAccount');
    }

    export function usersAccount_remove(){
        localStorage.removeItem('usersAccount');
    }
// USER
