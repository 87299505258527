import api from '@/services/api';
import { load, load_close } from '@/services/load';
import { usersAccount } from '@/storages/auth';

// BEFOREEACH
    export function beforeEach(to, form, next){
        load(1);

        response(to, form, next);
    }


    const response = async (to, form, next) => {
        var $next = 0;
        var request = {};

        // CADASTRO
            if(to.name.match(`cadastro_step`)){
                let response = await api(`/user/${to.params.step}`);
                if(response){ $next = 1; request = Object.assign(request, response); }
            }
        // CADASTRO

        // DASHBOARD
            else if(to.name.match(`dashboard`)){
                let response = await api(`/dashboard`, { usersAccount: usersAccount() });
                if(response){ $next = 1; request = Object.assign(request, response); }

                if(to.name.match(`dashboard_get_`)){
                    // CLIENTES
                        // TABLE
                            if(to.params.get_1==`clientes` && to.params.get_2==null){
                                let response = await api(`/customers`);
                                if(response){ $next = 1; request = Object.assign(request, response); }
                            }
                        // TABLE

                        // EDIT
                            else if(to.params.get_1==`clientes` && to.params.get_2!=null){
                                let response = await api(`/customers/${to.params.get_2}`);
                                if(response){ $next = 1; request = Object.assign(request, response); }
                            }
                        // EDIT
                    // CLIENTES


                    // GRUPOS
                        // TABLE
                            if(to.params.get_1==`grupos` && to.params.get_2==null){
                                let response = await api(`/groups`);
                                if(response){ $next = 1; request = Object.assign(request, response); }
                            }
                        // TABLE

                        // EDIT
                            else if(to.params.get_1==`grupos` && to.params.get_2!=null){
                                let response = await api(`/groups/${to.params.get_2}`);
                                if(response){ $next = 1; request = Object.assign(request, response); }
                            }
                        // EDIT
                    // GRUPOS

                    // COBRANCAS
                        // TABLE
                            if(to.params.get_1==`cobrancas` && to.params.get_2==null){
                                let response = await api(`/payments`);
                                if(response){ $next = 1; request = Object.assign(request, response); }
                            }
                            if(to.params.get_1==`cobrancas` && (to.params.get_2==`avulsas` || to.params.get_2==`parcelamentos` || to.params.get_2==`assinaturas`)){
                                let response = await api(`/payments`, { type: to.params.get_2 });
                                if(response){ $next = 1; request = Object.assign(request, response); }
                            }
                        // TABLE
                    // COBRANCAS

                    // LINK_DE_PAGAMENTO
                        // TABLE
                            if(to.params.get_1==`link_de_pagamento` && to.params.get_2==null){
                                let response = await api(`/payments`, { type: `link_de_pagamento` });
                                if(response){ $next = 1; request = Object.assign(request, response); }
                            }
                        // TABLE
                    // COBRANCAS

                }
            }
        // DASHBOARD

        // ELSE
            else {
                $next = 1;
            }
        // ELSE


        // NEXT
            if($next){
                if(request){
                    to.params['request'] = request;
                }
                next(true);
            }
        // NEXT
        
        // PREVIOUS
            else {
                load_close();
            }
        // PREVIOUS

    }
// BEFOREEACH


// AFTEREACH
    export function afterEach(to, form){
        load_close();
    }
// AFTEREACH
